<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="formatUsersRegistrationDate()"
      :pagination-options="{
        enabled: true,
      }"
      :theme="isDarkMode()"
      @on-row-click="onRowClick"
    >
      <div slot="table-actions" class="table-actions-container">
        <CInput label="Start Date" type="date" v-model="selectedDate.start" />
        <CInput
          label="Stop Date"
          type="date"
          class="stop-date"
          v-model="selectedDate.end"
        />
        <CInput
          placeholder="Username"
          class="search-username"
          v-model="usernameSearch"
        >
          <template #prepend-content><CIcon name="cil-user" /></template>
        </CInput>

        <CButton @click="filterUsers" color="facebook" class="filter-button">
          <CIcon name="cil-settings" />&nbsp;Filter
        </CButton>
      </div>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'after'">
          <template>
            <CDropdown color="transparent p-0" placement="bottom-end">
              <template #toggler-content>
                <CButton color="facebook" class="px-4"> Action </CButton>
              </template>
              <CDropdownItem style="justify-content: center"
                >View Profile
              </CDropdownItem>
              <CDropdownItem
                ><CButton
                  :color="buttonColor()"
                  @click="showModal"
                  variant="ghost"
                  class="px-4"
                >
                  Make Admin
                </CButton>
              </CDropdownItem>
              <CDropdownItem style="justify-content: center"
                >Suspend</CDropdownItem
              >
            </CDropdown>
          </template>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <CModal
      :title="dynamicUserTitle()"
      :show.sync="primaryModal"
      color="dark"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol md="1"> </CCol>
        <CCol md="10">
          <CCard class="mx-4 mb-0">
            <CCardBody class="m-8">
              <CForm label="Units" label-for="txtUnits" :label-cols="3">
                <CInput
                  id="txtUnits"
                  type="text"
                  v-model="currentUserRow.username"
                  readonly
                ></CInput>
              </CForm>

              <CSelect
                horizontal
                :options="getUserRoles()"
                placeholder="Select Role"
                :value.sync="selectedUserRoleValue"
              />

              <CButton
                block
                color="facebook"
                class="mb-2"
                type="submit"
                :disabled="loading"
                @click="upgradeUser"
                >Upgrade User</CButton
              >
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <div style="margin-bottom: 1.5rem" slot="footer-wrapper"></div>
    </CModal>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { DatePicker } from 'v-calendar';
import {} from '@/services/notification.service';

export default {
  data() {
    return {
      columns: [
        { label: 'Username', field: 'username' },
        { label: 'Units', field: 'credit_balance' },
        { label: 'User Type', field: 'user_type' },
        { label: 'User Role', field: 'user_role' },
        { label: 'User Status ', field: 'user_status' },
        { label: 'Phone', field: 'phone_no' },
        { label: 'Password Hint ', field: 'password_hint' },
        { label: 'Code', field: 'activation_code' },
        { label: 'Registered At', field: 'registered_at' },
        { label: 'Action', field: 'after' },
      ],
      selectedDate: {
        start: '',
        end: '',
      },
      usernameSearch: '',
      primaryModal: false,
      currentUserRow: { username: '' },
      selectedUserRoleValue: '',
    };
  },

  notifications: {
    futureDateError: {
      title: 'Failed',
      message: 'You can not search future Data! Please adjust your End Date ',
      type: 'warn',
    },
    startDateError: {
      title: 'Failed',
      message: 'Please pick a start date',
      type: 'warn',
    },
    stopDateError: {
      title: 'Failed',
      message: 'Please pick a stop date',
      type: 'warn',
    },
    noInputError: {
      title: 'Failed',
      message: 'Please Use Either Date Or Username To Filter But Not Both',
      type: 'error',
    },
    noSelectedUserRoleError: {
      title: 'Failed',
      message: 'Please Select A User Role',
      type: 'error',
    },
    updateSuccess: {
      title: 'Success',
      message: `User has been upgraded!`,
      type: 'success',
    },
    updateFailed: {
      title: 'Failed',
      message:
        'Update failed at this time, kindly check your network and try again!',
      type: 'error',
    },
  },

  components: {
    VueGoodTable,
    'v-date-picker': DatePicker,
  },
  created() {
    this.fetchUsers();
    this.fetchInternalStaffsRoles();
  },

  computed: {
    ...mapGetters('admin', [
      'users',
      'internalStaffRoles',
      'loading',
      'serverResponse',
    ]),
    ...mapState('coreuiStore', ['darkMode']),
  },

  methods: {
    ...mapActions('admin', [
      'fetchUsers',
      'fetchInternalStaffsRoles',
      'postInternalStaffs',
    ]),

    isDarkMode() {
      return this.darkMode ? 'nocturnal' : null;
    },

    buttonColor() {
      return this.darkMode ? 'info' : 'black';
    },

    onRowClick(params) {
      if (this.primaryModal == true) return;
      this.currentUserRow = params.row;
    },

    showModal() {
      this.primaryModal = true;
    },

    closeModal() {
      this.currentUserRow = { username: '' };
      this.selectedUserRoleValue = '';
      this.primaryModal = false;
    },

    dynamicUserTitle() {
      if (!this.selectedUserRoleValue)
        return `Upgrade ${this.currentUserRow.username} `;

      const label = this.getUserRoles().filter((item) => {
        return item.value == this.selectedUserRoleValue;
      });

      return `Upgrade ${this.currentUserRow.username} to ${label[0].label}`;
    },

    async upgradeUser() {
      if (!this.selectedUserRoleValue) return this.noSelectedUserRoleError();

      let upgradeData = {
        user_id: this.currentUserRow.id,
        user_role_id: this.selectedUserRoleValue,
      };

      let status = await this.postInternalStaffs({ data: upgradeData });

      if (!status) {
        this.closeModal();
        this.updateFailed({ message: this.serverResponse.message });
      } else {
        this.updateSuccess();
        this.closeModal();
      }
    },

    getUserRoles() {
      return this.internalStaffRoles.user_roles.map((role) => ({
        label: role.name,
        value: role.id,
      }));
    },

    formatUsersRegistrationDate() {
      const dayJs = dayjs.extend(advancedFormat);
      return this.users.users.map((a) => ({
        ...a,
        registered_at: dayJs(a.registered_at).format('Do MMM YYYY'),
      }));
    },

    filterUsers() {
      const startDate = dayjs(this.selectedDate.start).format('YYYY-MM-DD');
      const stopDate = dayjs(this.selectedDate.end).format('YYYY-MM-DD');
      const isAfterToday = dayjs(stopDate).isAfter(dayjs());

      if (
        (this.usernameSearch == '' &&
          this.selectedDate.start == '' &&
          this.selectedDate.end == '') ||
        (this.usernameSearch != '' &&
          startDate != 'Invalid Date' &&
          stopDate != 'Invalid Date')
      ) {
        this.fetchUsers();
        return this.noInputError();
      }

      if (this.usernameSearch != '')
        return this.fetchUsers(`?username=${this.usernameSearch}`);

      if (startDate == 'Invalid Date') return this.startDateError();

      if (stopDate == 'Invalid Date') return this.stopDateError();

      if (isAfterToday) return this.futureDateError();

      const searchQuery = `?start-date=${startDate}&stop-date=${stopDate}`;
      this.fetchUsers(searchQuery);
    },
  },
};
</script>

<style scoped>
.table-actions-container {
  display: flex;
  font-weight: bold;
  font-size: 1.1rem;
  align-items: center;
  margin-bottom: -1.1rem;
  flex-wrap: wrap;
}
.filter-button {
  margin-bottom: 1rem;
}
.search-username {
  margin-right: 1rem;
}

@media (min-width: 345px) {
  .search-username {
    margin-top: 0.5rem;
    margin: 0;
  }
  .filter-button {
    margin-top: 1rem;
  }
}
@media (min-width: 400px) {
  .stop-date {
    margin-left: 5px;
  }
  .filter-button {
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 400px) {
  .search-username {
    margin-bottom: 10px;
  }
}

@media (min-width: 624px) {
  .search-username {
    margin-left: 5px;
  }
}

@media (min-width: 629px) {
  .search-username {
    margin-left: 5px;
    margin-top: 1.8rem;
  }
  .filter-button {
    margin-top: 0;
  }
}

@media (min-width: 720px) {
  .filter-button {
    margin-right: 1rem;
  }
}

@media (min-width: 736px) {
  .filter-button {
    margin-top: 2rem;
    margin-right: 1rem;
    margin-bottom: 1.2rem;
  }
  .search-username {
    margin-inline: 1rem;
    margin-top: 1.5rem;
  }
}

@media (min-width: 763px) {
  .filter-button {
    margin-top: 2rem;
  }
}

@media (min-width: 768px) {
  .filter-button {
    margin-top: 0;
  }
}

@media (min-width: 793px) {
  .filter-button {
    margin-top: 2rem;
  }
}
@media (min-width: 992px) {
  .filter-button {
    margin-top: 0;
  }
}
@media (min-width: 1049px) {
  .filter-button {
    margin-top: 2rem;
  }
}
</style>
